html {
  height: 100%;
}

p {
  margin-top: .4rem;
  margin-bottom: .4rem;
}

ul, ol {
  margin-top: .2rem;
}

img {
  width: 100%;
  padding: 1rem 0;
}

.centered-image {
  text-align: center;
  width: 100%;
}

strong {
  font-weight: bold;
}

body {
  flex-direction: column;
  height: 100%;
  margin: 0;
  font-family: FrutigerNeue LT Pro;
  display: flex;
}

a {
  color: #000000de;
}

a:hover {
  color: #cc071e;
}

h4 {
  margin-bottom: .5rem;
}

#header {
  text-align: center;
  z-index: 20;
  align-items: center;
  height: 5rem;
  display: flex;
  box-shadow: 0 8px 6px -6px #d3d3d3;
}

#header .logo {
  width: unset;
  height: 100%;
  padding: 0;
}

#header h1 {
  color: #cc071e;
  flex-grow: 100;
  font-size: 1.2rem;
  font-weight: 900;
}

#header input:not(#search-input) {
  display: none;
}

#header input:not(#search-input):checked ~ div.dl-menu-content {
  display: block;
}

#header #menu-label {
  cursor: pointer;
  border: none;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

#header #menu-label:hover, #header #menu-label:focus {
  background-color: #d3d3d3;
}

#header #menu-label svg {
  width: 40px;
  height: 40px;
  margin-top: 5px;
}

.dl-menu-content {
  min-width: 160px;
  display: none;
  position: absolute;
  top: 5em;
  right: 10px;
}

.dl-button {
  color: #fff;
  background-color: #cc071e;
  border-radius: 8px;
  height: 20px;
  margin-top: 10px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dl-button:hover {
  color: #fff;
  background-color: #b3061a;
}

#main {
  height: calc(100% - 5rem);
  position: relative;
}

#map {
  z-index: 1;
  width: 100%;
  height: 100%;
}

#detail {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.topic {
  stroke: #0000;
  fill: #0000;
}

.topic:hover, .selected-item {
  stroke: #cc071e;
  stroke-width: 1.5px;
  stroke-opacity: .7;
  fill: #cc071e;
  fill-opacity: .2;
}

.highlighted-item {
  stroke: #ff0;
  stroke-width: 1.5px;
  stroke-opacity: .7;
  fill-opacity: .2;
  fill: #ff0;
}

#close-button {
  color: gray;
  cursor: pointer;
  float: right;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin: .7em .7em 0 0;
  display: flex;
}

#close-button:hover {
  color: #fff;
  background-color: #d3d3d3;
}

.times-sign {
  font-size: 2em;
}

.close-text {
  margin-bottom: .5em;
  font-size: .8em;
  font-weight: lighter;
}

.content {
  margin: 20px 30px 60px;
}

.content > h1, .topicname {
  color: #cc071e;
  margin: 0 0 .2rem;
  font-family: FrutigerNeue LT Pro;
  font-size: 1.7rem;
  font-weight: bold;
}

.content > p, .text {
  font-family: FrutigerNeue LT Pro;
  font-size: 1rem;
}

.breadcrumb {
  color: #4d4d4d;
  margin-bottom: 1.2rem;
  font-family: FrutigerNeue LT Pro;
  font-size: 1rem;
}

.content > h2, .subtitle {
  margin-top: 2rem;
  margin-bottom: .5rem;
  font-family: FrutigerNeue LT Pro;
  font-size: 1.4rem;
  font-weight: bold;
}

@media (width >= 50rem) {
  #header h1 {
    width: unset;
    font-size: 2.1rem;
  }

  #main {
    display: flex;
  }

  #map {
    flex: 60%;
  }

  #detail {
    position: unset;
    flex: 40%;
  }
}

@media (width <= 470px) {
  #header > h1 {
    display: none;
  }
}

a[href="https://leafletjs.com"] {
  display: none;
}

#search-icon {
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: .5rem;
  transition: all .25s ease-in-out;
}

#search-icon:hover {
  background: #d3d3d3;
}

#search-icon svg {
  fill: #cc071e;
  width: 32px;
  height: 32px;
}

#search-icon.active {
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  transform: translateX(32px)translateY(-12px);
}

#search-input {
  opacity: 0;
  border: 1px solid #4d4d4d;
  border-radius: 8px;
  width: 0;
  height: 32px;
  transition: all .3s ease-in-out;
}

#search-input.active {
  opacity: 1;
  width: 200px;
  margin-right: 8px;
  padding: 0 8px;
}

#search-input:focus {
  outline: -webkit-focus-ring-color auto 0;
  border-color: #4d4d4d;
  box-shadow: 0 0 10px #000000de;
}

.filler {
  width: 0;
}

@media (width <= 470px) {
  .filler {
    width: 100%;
  }
}
/*# sourceMappingURL=index.60371917.css.map */
