$bbv-red: #cc071e;
$hover-color: lightgray;
$bbv-gray: rgba(0, 0, 0, 0.87);
$gray: #4D4D4D;

html {
  height: 100%;
}

p {
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
}

ul,
ol {
  margin-top: 0.2rem;
}

img {
  width: 100%;
  padding: 1rem 0;
}

.centered-image {
  width: 100%;
  text-align: center;
}

strong {
  font-weight: bold;
}

body {
  display: flex;
  font-family: "FrutigerNeue LT Pro";
  margin: 0;
  height: 100%;
  flex-direction: column;
}

a {
  color: $bbv-gray;
  &:hover {
    color: $bbv-red;
  }
}

h4 {
  margin-bottom: 0.5rem;
}

#header {
  display: flex;
  height: 5rem;
  text-align: center;
  align-items: center;
  box-shadow: 0 8px 6px -6px lightgray;
  z-index: 20;

  .logo {
    height: 100%;
    padding: 0;
    width: unset;
  }

  h1 {
    flex-grow: 100;
    color: $bbv-red;
    font-size: 1.2rem;
    font-weight: 900;
  }

  input:not(#search-input) {
    display: none;

    &:checked ~ div.dl-menu-content {
      display: block;
    }
  }

  #menu-label {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    border-radius: 100%;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $hover-color;
    }

    svg {
      height: 40px;
      width: 40px;
      margin-top: 5px;
    }
  }
}

.dl-menu-content {
  display: none;
  position: absolute;
  right: 10px;
  top: 5em;
  min-width: 160px;
}

.dl-button {
  display: inline-block;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  height: 20px;
  background-color: $bbv-red;
  border-radius: 8px;
  margin-top: 10px;

  &:hover {
    background-color: darken($bbv-red, 5%);
    color: white;
  }
}

#main {
  position: relative;
  height: calc(100% - 5rem);
}

#map {
  width: 100%;
  height: 100%;
  z-index: 1;
}

#detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: none;
  z-index: 10;
  background-color: white;
}

.topic {
  stroke: transparent;
  fill: transparent;

  &:hover {
    stroke: $bbv-red;
    stroke-width: 1.5px;
    stroke-opacity: 0.7;
    fill: $bbv-red;
    fill-opacity: 0.2;
  }
}

.selected-item {
  stroke: $bbv-red;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
  fill: $bbv-red;
  fill-opacity: 0.2;
}

.highlighted-item {
  stroke: yellow;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
  fill-opacity: 0.2;
  fill: yellow;
}

#close-button {
  margin: 0.7em 0.7em 0 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  color: gray;
  cursor: pointer;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $hover-color;
    color: white;
  }
}

.times-sign {
  font-size: 2em;
}

.close-text {
  font-size: 0.8em;
  margin-bottom: 0.5em;
  font-weight: lighter;
}

.content {
  margin: 20px 30px 60px 30px;
}

.content > h1,
.topicname {
  color: $bbv-red;
  font-family: "FrutigerNeue LT Pro";
  font-weight: bold;
  font-size: 1.7rem;
  margin: 0 0 0.2rem 0;
}

.content > p,
.text {
  font-family: "FrutigerNeue LT Pro";
  font-size: 1rem;
}

.breadcrumb {
  color: $gray;
  font-size: 1rem;
  font-family: "FrutigerNeue LT Pro";
  margin-bottom: 1.2rem;
}

.content > h2,
.subtitle {
  font-family: "FrutigerNeue LT Pro";
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 50rem) {
  #header {
    h1 {
      font-size: 2.1rem;
      width: unset;
    }
  }

  #main {
    display: flex;
  }

  #map {
    flex: 60%;
  }

  #detail {
    flex: 40%;
    position: unset;
  }
}

@media (max-width: 470px){
  #header > h1 {
    display: none;
  }
}

// removed leaflet as they had an ukranian political statement with a non acceptable reference at the end
// removed based on https://gis.stackexchange.com/questions/192088/how-to-remove-attribution-in-leaflet
a[href="https://leafletjs.com"] {
    display: none;
}

#search-icon {
  opacity: 1;
  width: 32px;
  height: 32px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover{
    background: $hover-color;
  }
  svg {
    fill: $bbv-red;
    width: 32px;
    height: 32px;
  }

  &.active{
    transform: translateX(32px) translateY(-12px);
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
  }
}

#search-input{
  width: 0;
  height: 32px;
  border: 1px solid $gray;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.active{
    margin-right: 8px;
    padding: 0 8px;
    width: 200px;
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 10px $bbv-gray;
    border-color: $gray;
    outline: -webkit-focus-ring-color auto 0;
  }
}

.filler{
  width: 0;
  @media (max-width: 470px) {
    width: 100%;
  }
}
